import { graphql, Link } from 'gatsby';
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {
  Inner as InnerElement,
  GenericPageWrapper,
  LegalContentContainer,
} from '../components/Elements';
import SectionTitleBody from '../components/SectionTitleBody';
import SEO from '../components/SEO';
import PhoneCTA from '../components/PhoneCTA';

import { colors } from '../consts/style';

const Inner = styled(InnerElement)`
  flex-direction: column;
  a {
    color: ${(props) => props.theme.colors.purple.primary};
  }
  .legal-list {
    margin-top: 4.6rem;
    ${(props) => props.theme.font.p2};
  }
`;

Legal.propTypes = { data: PropTypes.object.isRequired };

function Legal({ data: { allPrismicCmsLegal: pageData } }) {
  const seoData = {
    title: 'Braid - Legal',
    description: 'Braid legal pages.',
    image: null,
    slug: '/legals',
  };

  function renderLegalPages() {
    const orderedDocs = pageData.nodes.reduce((acc, cur) => {
      if (!acc[cur.data.category]) acc[cur.data.category] = [cur];
      else acc[cur.data.category].push(cur);
      return acc;
    }, {});

    return Object.keys(orderedDocs).map((key) => {
      const arr = orderedDocs[key];
      return (
        <div key={key} className="legal-block">
          <h4>{key}</h4>
          <ul>
            {arr.map((page) => (
              <li key={page.uid}>
                <Link to={`/legal/${page.uid}`} title={page.data.title}>
                  {page.data.title}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      );
    });
  }

  return (
    <GenericPageWrapper>
      <SEO {...seoData} />
      <LegalContentContainer>
        <Inner>
          <SectionTitleBody
            title={{
              text: 'Braid Policies',
              element: 'h2',
              maxWidth: '71.3rem',
              maxWidthMobile: '36rem',
            }}
            color={colors.generics.black}
          />
          <div className="legal-list">{renderLegalPages()}</div>
        </Inner>
      </LegalContentContainer>
      {/* <PhoneCTA buttonText="Get the App"/> */}
    </GenericPageWrapper>
  );
}

export default Legal;

export const legalPageQuery = graphql`
  {
    allPrismicCmsLegal {
      nodes {
        uid
        data {
          title
          category
        }
      }
    }
  }
`;
